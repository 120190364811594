<template>
  <el-main class="rh-table">
    <h1>Thèses</h1>
    <app-table
      tablenom="theses"
      :cols="colonnes"
      :items="theses"
      :loadstatus="thesesLoadStatus"
      :deletestatus="theseDeleteStatus"
      :formroutes="thFormRoutes"
      :actions="thActions"
    >
    </app-table>
  </el-main>
</template>
<script>
import AppTable from "@/components/rh/ui/table/AppTable";

export default {
  components: { "app-table": AppTable },
  data() {
    return {
      idMed: "",
      thFormRoutes: {
        edit: { route: "theseedit", params: { idth: "id" } },
        create: { route: "thesecreate" },
      },
    };
  },
  computed: {
    thesesLoadStatus() {
      // 1 = loading, 2 = succès, 3 = échec du chargement des données
      return this.$store.state.th.thesesLoadStatus;
    },
    theseDeleteStatus() {
      // 1 = deleting, 2 = succès, 3 = échec
      return this.$store.state.th.theseDeleteStatus;
    },
    theses() {
      return this.$store.state.th.theses;
    },
    colonnes() {
      return this.$store.state.th.colonnes;
    },
    thActions() {
      return this.$store.state.th.actions;
    },
  },
};
</script>
